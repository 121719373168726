module.exports = function () {

    /**
     * initCarousel
     *
     * Initiates the owl carousel on the index page
     *
     * @returns undefined
     */
    var initCarousel = function() {


        var carouselMain = $(".header__carousel");
        var carouselTimeout = 7500;

        carouselMain.on("initialized.owl.carousel translated.owl.carousel", function(event) {

        });

        var carouselMainOptions = {

            // Loading
            items: 1,
            lazyLoad: true,

            // Timing
            autoplay: true,
            autoplayTimeout: carouselTimeout,

            animateOut: 'fadeOut',

            // Control
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,

        };

        if(carouselMain.children().length > 1) {

            carouselMainOptions.loop = true;
        }

        carouselMain.owlCarousel(carouselMainOptions);

    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initCarousel();

        },

        initCarousel: initCarousel,
    };

}; // End module