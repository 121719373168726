module.exports = function () {

    /**
     * initBookingForm
     * 
     * Code that prepares the booking form
     *
     * @return undefined
     */
    var initBookingForm = function() {

        /** Hide uneccesary attendee forms */
        $("[data-attendee-no]").hide();
        $("[data-attendee-no]").find("input").removeAttr("required");
        $("[data-attendee-no]").find("select").removeAttr("required");

        $("[data-attendee-no='1']").show();
        $("[data-attendee-no='1']").find("input.mand").attr("required", "required");
        $("[data-attendee-no='1']").find("select.mand").attr("required", "required");

        /** Add event handler to the radio buttons change event */
        $(".booking__attendees-count .form-group--radio input").on("change", function(e) {

            $("[data-attendee-no]").hide();
            $("[data-attendee-no]").find("select, input").removeAttr("required");
            
            var selection = parseInt($(this).val());

            for(var i = 0; i <= selection; i++) {

                $("[data-attendee-no='"+i+"']").show();
                $("[data-attendee-no='"+i+"']").find("input.mand").attr("required", "required");
                $("[data-attendee-no='"+i+"']").find("select.mand").attr("required", "required");
            }
        });

        $(".js-use-lead-details").on("change", function() {

            var $checkbox = $(this);

            $("[data-lead-details]").each(function() {

                if($checkbox.is(":checked")) {

                    // Store the old entered values:
                    $(this).attr("data-entered-details", $(this).val());

                    $(this).val($(this).attr("data-lead-details"));

                } else {

                    // Restore the old values:
                    $(this).val($(this).attr("data-entered-details"));
                    
                }

            });
        });
    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initBookingForm();
        },
    };

}; // End module