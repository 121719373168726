module.exports = function () {

    var initEventTabs = function() {

        $("[data-tab-target]").on("click", function() {

            console.log("CLICK");

            // Grab the target
            var $target = $("[data-tab-id="+$(this).attr("data-tab-target")+"]");

            // Check that the target exists:
            if($target.length) {

                // Hide all tabs:
                $("[data-tab-id]").hide();
                $("[data-tab-target]").removeClass("active");

                // Show requested target:
                $target.show();
                $(this).addClass("active");
            }

        });

        $("[data-tab-target]").eq(0).trigger("click");
    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initEventTabs();
        },
    };

}; // End module