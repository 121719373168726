module.exports = function () {

    //Your functions goes here then called in init

    var searchAutoComplete = function () {

        /**
         * Auto suggest functionality. Defaults to use jQuery UI, but you can use
         * whatever plugin you feel comfortable with. Point the source to /search/suggest
         * and the term variable is 'term'. It will return a multi-dim JSON array
         * with a 'label' and 'value' for each term.
         */
        $("#js-search-form input#keywords").autocomplete({
            source: $("base").attr("href") + "search/suggest",
            delay: 500
        });
    };

    /**
     * loadSprites
     *
     * Loads the SVG spritesheet via AJAX and injects in to the DOM,
     * the SVG's can then be used using the use tag: i.e.
     * <svg role="img" title="SVG logo">
     *      <use xlink:href="<?= $this->getRequest()->getRequestUri() ?>#name-of-svg-file"></use>
     * </svg>
     *
     * @return undefined
     */
    var loadSprites = function () {

        $.ajax({
            type: "GET",
            url: "assets/images/svg-icons.svg",
            success: function (data) {

                var div = $("<div class='svg-sprite-wrap' />");
                div.html($(data).find("svg"));

                $("body").prepend(div);
            }
        });
    };


    /**
     * initLazyload
     *
     * Initiates the lazyload script that AJAXs images in as a background image.
     * Applying a ".lazy" class to an element and setting it's "data-original" attribute
     * to be the source of the image will activate lazy loading on that element
     *
     * @return undefined
     */
    var initLazyload = function () {

        $('.lazyload').lazyload({

            failure_limit: 100,
            threshold: 200
        });

    };

    /**
     * initHamburgerMenu
     *
     * Initiates the hamburger menu used on mobile.
     *
     * @return undefined
     */
    var initHamburgerMenu = function () {

        $(".header__hamburger").on("click", function() {

            $(this).toggleClass("active");
        });

    };

    /**
     * initSelectPicker
     *
     * Initiates a custom bootstrap-like dropdown style to all select boxes, which can be styled
     * to the designs requirements.
     *
     * @return undefined
     */
    var initSelectPicker = function () {

        $('select').selectpicker({
          size: 4
        });

    };

    /**
     * initShowMoreEvents
     *
     * Enables the "Load upcoming events" button on the event list to "load" the next set of results.
     * If there are no more results, hide the button.
     *
     * @return undefined
     */
    var initShowMoreEvents = function () {

        // console.log("SHOWEVENTS");

        var totalEvents = $("[data-event-index]").length;
        var shownEvents = 9;


        var checkButtonRequirement = function() {

            // console.log("Total Events = "+ totalEvents);
            // console.log("Shown Events = "+ shownEvents);

            if(shownEvents >= totalEvents) {

                $(".js-show-more-events").hide();
            } else {

                $(".js-show-more-events").show();
            }
        };

        checkButtonRequirement();

        $(".js-show-more-events").on("click", function() {

            // Show 9 more events (If available):
            for(var i = shownEvents; i < (shownEvents + 9); i++) {

                if($("[data-event-index="+i+"]").length) {

                    $("[data-event-index="+i+"]").removeClass("hidden");
                    shownEvents++;
                }
            }

            checkButtonRequirement();

        });

    };

    


    return {
        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {
            
            loadSprites();
            initLazyload();

            initHamburgerMenu();
            
            initShowMoreEvents();

            initSelectPicker();

        }

    };

}; // End module