module.exports = function () {

    var initScrollToTarget = function() {

        $("[data-scroll-to]").on("click", function() {

            var target = $($(this).attr("data-scroll-to"));
            var customOffset = $(this).attr("data-scroll-offset");
            var pushState = $(this).attr("data-scroll-pushstate");

            if(target.length !== 0) {

                scrollToTarget(target, customOffset, pushState);
            } else {

                console.warn("A target was not specified or did not exist on this page");
            }

        });
    };

    /**
     * scrollToTarget
     *
     * Scrolls to an element, using the target attribute as the target to scroll to.
     *
     * @param options Accepts an array of options:
     * @option href string The href of the targer you want to scroll to
     */
    var scrollToTarget = function(target, customOffset, pushState) {
        // If href missing, return.
        if (!target) return;

        var offset = 0;

        if(typeof customOffset === "number") {
            offset = customOffset;
        }

        // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
        // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
        var $target = $(target);

        // Older browsers without pushState might flicker here, as they momentarily
        // jump to the wrong position (IE < 10)
        if ($target.length) {
            $('html, body').animate({
                scrollTop: $target.offset().top - offset
            });
            if(pushState) {
                if (history && "pushState" in history) {
                    history.pushState({}, document.title, window.location.pathname + target);
                    return false;
                }
            }
        }
    };

    return {

        /*
         * init
         *
         * This method is called first thing to initiate all the private methods
         *
         * @return object this
        */
        init: function() {

            initScrollToTarget();

            return this;

        },

        scrollToTarget: scrollToTarget,

    };

};