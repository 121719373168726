(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
module.exports = function () {

    /**
     * initBookingForm
     * 
     * Code that prepares the booking form
     *
     * @return undefined
     */
    var initBookingForm = function() {

        /** Hide uneccesary attendee forms */
        $("[data-attendee-no]").hide();
        $("[data-attendee-no]").find("input").removeAttr("required");
        $("[data-attendee-no]").find("select").removeAttr("required");

        $("[data-attendee-no='1']").show();
        $("[data-attendee-no='1']").find("input.mand").attr("required", "required");
        $("[data-attendee-no='1']").find("select.mand").attr("required", "required");

        /** Add event handler to the radio buttons change event */
        $(".booking__attendees-count .form-group--radio input").on("change", function(e) {

            $("[data-attendee-no]").hide();
            $("[data-attendee-no]").find("select, input").removeAttr("required");
            
            var selection = parseInt($(this).val());

            for(var i = 0; i <= selection; i++) {

                $("[data-attendee-no='"+i+"']").show();
                $("[data-attendee-no='"+i+"']").find("input.mand").attr("required", "required");
                $("[data-attendee-no='"+i+"']").find("select.mand").attr("required", "required");
            }
        });

        $(".js-use-lead-details").on("change", function() {

            var $checkbox = $(this);

            $("[data-lead-details]").each(function() {

                if($checkbox.is(":checked")) {

                    // Store the old entered values:
                    $(this).attr("data-entered-details", $(this).val());

                    $(this).val($(this).attr("data-lead-details"));

                } else {

                    // Restore the old values:
                    $(this).val($(this).attr("data-entered-details"));
                    
                }

            });
        });
    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initBookingForm();
        },
    };

}; // End module
},{}],2:[function(require,module,exports){
module.exports = function () {

    var initEventTabs = function() {

        $("[data-tab-target]").on("click", function() {

            console.log("CLICK");

            // Grab the target
            var $target = $("[data-tab-id="+$(this).attr("data-tab-target")+"]");

            // Check that the target exists:
            if($target.length) {

                // Hide all tabs:
                $("[data-tab-id]").hide();
                $("[data-tab-target]").removeClass("active");

                // Show requested target:
                $target.show();
                $(this).addClass("active");
            }

        });

        $("[data-tab-target]").eq(0).trigger("click");
    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initEventTabs();
        },
    };

}; // End module
},{}],3:[function(require,module,exports){
module.exports = function () {

    /**
     * initCarousel
     *
     * Initiates the owl carousel on the index page
     *
     * @returns undefined
     */
    var initCarousel = function() {


        var carouselMain = $(".header__carousel");
        var carouselTimeout = 7500;

        carouselMain.on("initialized.owl.carousel translated.owl.carousel", function(event) {

        });

        var carouselMainOptions = {

            // Loading
            items: 1,
            lazyLoad: true,

            // Timing
            autoplay: true,
            autoplayTimeout: carouselTimeout,

            animateOut: 'fadeOut',

            // Control
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,

        };

        if(carouselMain.children().length > 1) {

            carouselMainOptions.loop = true;
        }

        carouselMain.owlCarousel(carouselMainOptions);

    };

    return {

        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {

            initCarousel();

        },

        initCarousel: initCarousel,
    };

}; // End module
},{}],4:[function(require,module,exports){
module.exports = function () {

    //Your functions goes here then called in init

    var searchAutoComplete = function () {

        /**
         * Auto suggest functionality. Defaults to use jQuery UI, but you can use
         * whatever plugin you feel comfortable with. Point the source to /search/suggest
         * and the term variable is 'term'. It will return a multi-dim JSON array
         * with a 'label' and 'value' for each term.
         */
        $("#js-search-form input#keywords").autocomplete({
            source: $("base").attr("href") + "search/suggest",
            delay: 500
        });
    };

    /**
     * loadSprites
     *
     * Loads the SVG spritesheet via AJAX and injects in to the DOM,
     * the SVG's can then be used using the use tag: i.e.
     * <svg role="img" title="SVG logo">
     *      <use xlink:href="<?= $this->getRequest()->getRequestUri() ?>#name-of-svg-file"></use>
     * </svg>
     *
     * @return undefined
     */
    var loadSprites = function () {

        $.ajax({
            type: "GET",
            url: "assets/images/svg-icons.svg",
            success: function (data) {

                var div = $("<div class='svg-sprite-wrap' />");
                div.html($(data).find("svg"));

                $("body").prepend(div);
            }
        });
    };


    /**
     * initLazyload
     *
     * Initiates the lazyload script that AJAXs images in as a background image.
     * Applying a ".lazy" class to an element and setting it's "data-original" attribute
     * to be the source of the image will activate lazy loading on that element
     *
     * @return undefined
     */
    var initLazyload = function () {

        $('.lazyload').lazyload({

            failure_limit: 100,
            threshold: 200
        });

    };

    /**
     * initHamburgerMenu
     *
     * Initiates the hamburger menu used on mobile.
     *
     * @return undefined
     */
    var initHamburgerMenu = function () {

        $(".header__hamburger").on("click", function() {

            $(this).toggleClass("active");
        });

    };

    /**
     * initSelectPicker
     *
     * Initiates a custom bootstrap-like dropdown style to all select boxes, which can be styled
     * to the designs requirements.
     *
     * @return undefined
     */
    var initSelectPicker = function () {

        $('select').selectpicker({
          size: 4
        });

    };

    /**
     * initShowMoreEvents
     *
     * Enables the "Load upcoming events" button on the event list to "load" the next set of results.
     * If there are no more results, hide the button.
     *
     * @return undefined
     */
    var initShowMoreEvents = function () {

        // console.log("SHOWEVENTS");

        var totalEvents = $("[data-event-index]").length;
        var shownEvents = 9;


        var checkButtonRequirement = function() {

            // console.log("Total Events = "+ totalEvents);
            // console.log("Shown Events = "+ shownEvents);

            if(shownEvents >= totalEvents) {

                $(".js-show-more-events").hide();
            } else {

                $(".js-show-more-events").show();
            }
        };

        checkButtonRequirement();

        $(".js-show-more-events").on("click", function() {

            // Show 9 more events (If available):
            for(var i = shownEvents; i < (shownEvents + 9); i++) {

                if($("[data-event-index="+i+"]").length) {

                    $("[data-event-index="+i+"]").removeClass("hidden");
                    shownEvents++;
                }
            }

            checkButtonRequirement();

        });

    };

    


    return {
        /*
         * init
         * 
         * This method is called first thing to initiate all the private methods
         *
         * @return undefined
         */
        init: function () {
            
            loadSprites();
            initLazyload();

            initHamburgerMenu();
            
            initShowMoreEvents();

            initSelectPicker();

        }

    };

}; // End module
},{}],5:[function(require,module,exports){
var app = {};
var module = {};

/*
 * ===============================
 * Application Files 
 * ===============================
 *
 * Application files must have an init method, as these files are
 * executed on load.
 *
 */
app.main = require('./app/app-main')().init();
app.index = require('./app/app-index')().init();
app.event = require('./app/app-event')().init();
app.booking = require('./app/app-booking')().init();

/*
 * ===============================
 * Modules
 * ===============================
 *
 * Additional helper modules
 *
 */
module.scrollToTarget = require('./modules/module-scrollToTarget')().init();
},{"./app/app-booking":1,"./app/app-event":2,"./app/app-index":3,"./app/app-main":4,"./modules/module-scrollToTarget":6}],6:[function(require,module,exports){
module.exports = function () {

    var initScrollToTarget = function() {

        $("[data-scroll-to]").on("click", function() {

            var target = $($(this).attr("data-scroll-to"));
            var customOffset = $(this).attr("data-scroll-offset");
            var pushState = $(this).attr("data-scroll-pushstate");

            if(target.length !== 0) {

                scrollToTarget(target, customOffset, pushState);
            } else {

                console.warn("A target was not specified or did not exist on this page");
            }

        });
    };

    /**
     * scrollToTarget
     *
     * Scrolls to an element, using the target attribute as the target to scroll to.
     *
     * @param options Accepts an array of options:
     * @option href string The href of the targer you want to scroll to
     */
    var scrollToTarget = function(target, customOffset, pushState) {
        // If href missing, return.
        if (!target) return;

        var offset = 0;

        if(typeof customOffset === "number") {
            offset = customOffset;
        }

        // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
        // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
        var $target = $(target);

        // Older browsers without pushState might flicker here, as they momentarily
        // jump to the wrong position (IE < 10)
        if ($target.length) {
            $('html, body').animate({
                scrollTop: $target.offset().top - offset
            });
            if(pushState) {
                if (history && "pushState" in history) {
                    history.pushState({}, document.title, window.location.pathname + target);
                    return false;
                }
            }
        }
    };

    return {

        /*
         * init
         *
         * This method is called first thing to initiate all the private methods
         *
         * @return object this
        */
        init: function() {

            initScrollToTarget();

            return this;

        },

        scrollToTarget: scrollToTarget,

    };

};
},{}]},{},[5])

