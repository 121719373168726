var app = {};
var module = {};

/*
 * ===============================
 * Application Files 
 * ===============================
 *
 * Application files must have an init method, as these files are
 * executed on load.
 *
 */
app.main = require('./app/app-main')().init();
app.index = require('./app/app-index')().init();
app.event = require('./app/app-event')().init();
app.booking = require('./app/app-booking')().init();

/*
 * ===============================
 * Modules
 * ===============================
 *
 * Additional helper modules
 *
 */
module.scrollToTarget = require('./modules/module-scrollToTarget')().init();